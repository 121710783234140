import { Component, Input } from "@angular/core";

@Component({
    selector: 'counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss']
})

export class CounterComponent {
    @Input() max: number = 1000;
    @Input() current: number = 0;
}