export interface IProjectPermissionResult {
    accessLevel: string,
    authorizedProjectIds: number[]
}

export const ProjectAccecssLevel = {
    None: 'None',
    All: 'All',
    Partial: 'Partial'
}
