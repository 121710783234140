import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Clone } from '~/classes/shared/clone';
import { UserInfo } from '~/models/shared/users/user-info';
import { ProjectService } from '~/services/shared/projects/project.service';

@Component({
    selector: 'project-detail-contacts',
    templateUrl: './project-detail-contacts.component.html',
    styleUrls: ['./project-detail-contacts.component.scss']
})

export class ProjectDetailContactsComponent {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = false;
    public projectEdit: any;

    public _owners: any;
    public entity = "CvxProject";

    constructor(private projectService: ProjectService
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnChanges(_args: any) {
        if (this.saveData) {
            this.saveDataEvent.emit(false);
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = Clone.deepCopy(this.project);
            this._owners = Clone.deepCopy(this.project.owners);

            if (!this._owners) {
                this._owners = [];
            }

            this.isLoading = false;
        }

    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public addOwner() {
        this._owners.push({ accountName: "", percentOwned: 0 });

        this.infoChange({ field: "owners", value: this._owners });
    }

    public deleteOwner(id: number) {
        this._owners = this._owners.filter((owner: any) => owner.accountId !== id);

        this.infoChange({ field: "owners", value: this._owners });
    }

    public ownerChange(index: number, field: any, value: any) {
        this._owners[index][field] = value;

        this.infoChange({ field: "owners", value: this._owners });
    }

    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;

        this.changeEvent.emit(this.projectEdit);
    }

    public userSelectionChange(field: string, event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const user: UserInfo = event;
            id = user.id;
            name = user.displayName;
        }

        this.infoChange({ field: `${field}Id`, value: id });
        this.infoChange({ field: field, value: name });
    }


    public isRequired(field: string): boolean {
        return this.projectService.isRequired(this.projectEdit, field);
    } 

    public getStatus() {
        return this.projectEdit && this.projectEdit.status ?
            this.projectEdit.status
            : (this.project && this.project.status ? this.project.status : null);
    }
}
