import { IEntityAuthorizer } from '~/classes/core/auth/entity-authorizer';
import { ProjectService } from '~/services/shared/projects/project.service';;
import { HttpService } from "~/services/core/http.service";
import { IProjectPermissionResult, ProjectAccecssLevel } from '~/services/shared/projects/models/project-permission-result';

export class ProjectsAuthorizer implements IEntityAuthorizer {

    public async isAuthorized(httpService: HttpService): Promise<boolean> {

        try {
            const permissionResult: IProjectPermissionResult = await this.getProjectPermissions(httpService);
            return permissionResult.accessLevel != ProjectAccecssLevel.None;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async isEntityAuthorized(httpService: HttpService, entityId: number): Promise<boolean> {
        try {
            const permissionResult: IProjectPermissionResult = await this.getProjectPermissions(httpService);
            const projectAuthorized = (permissionResult.accessLevel == ProjectAccecssLevel.All)
                || (permissionResult.accessLevel == ProjectAccecssLevel.Partial && permissionResult.authorizedProjectIds.indexOf(entityId) > -1);

            return permissionResult.accessLevel != ProjectAccecssLevel.None && projectAuthorized;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async isFeatureAuthorized(httpService: HttpService, feature: string): Promise<boolean> {
        try {
            const permissionResult: IProjectPermissionResult = await this.getProjectPermissions(httpService);
            const featureAuthorized = (permissionResult.accessLevel == ProjectAccecssLevel.All)
                || (permissionResult.accessLevel == ProjectAccecssLevel.Partial &&
                    feature !== ProjectFeatures.Charts &&
                    feature !== ProjectFeatures.AgreementDelete);

            return permissionResult.accessLevel != ProjectAccecssLevel.None && featureAuthorized;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    private async getProjectPermissions(httpService: HttpService): Promise<IProjectPermissionResult> {
        try {
            const projectService = new ProjectService(httpService);
            const permissionResult: IProjectPermissionResult = await projectService.getProjectPermissions();
            return permissionResult;
        } catch (error: any) {
            throw error;
        }
    }
}

export const ProjectFeatures = {
    Charts: 'Charts',
    AgreementDelete: 'AgreementDelete'
}
