<div style="position: relative;">
    <table class="table-x">
        <caption style="display: none;">ProjectInfo</caption>
        <tbody>
            <tr>
                <th scope="col">Status</th>
                <td [title]="projectStatusHoverText" style="position: relative;"><div class="status">{{ projectStatus }}</div></td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('priority') }">ADO Priority</th>
                <td *ngIf="!isEdit">{{ project.priority || '-' }}</td>
                <td *ngIf="isEdit">
                    <select [(ngModel)]="projectEdit.priority" (change)="setNumberValue('priority', $event)">
                        <option value="" disabled selected [selected]="!projectEdit.priority">...</option>
                        <option value="1" [selected]="projectEdit.priority === 1">1</option>
                        <option value="2" [selected]="projectEdit.priority === 2">2</option>
                        <option value="3" [selected]="projectEdit.priority === 3">3</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('executionStartDate') }">Start Date</th>
                <td *ngIf="!isEdit">{{ project.executionStartDate && project.executionStartDate.split('T')[0] | date: 'MM/dd/yyyy' || '-' }}</td>
                <td *ngIf="isEdit">
                    <sdk-textbox [value]="projectEdit.executionStartDate" validCharacters="calendar" pattern="MM/DD/YYYY" (changeCallBackEvent)="setStartDate($event)"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('ndaExecutionDate') }">NDA Execution Date</th>
                <td *ngIf="!isEdit">{{ project.ndaExecutionDate && project.ndaExecutionDate.split('T')[0] | date: 'MM/dd/yyyy' || '-' }}</td>
                <td *ngIf="isEdit">
                    <sdk-textbox [value]="projectEdit.ndaExecutionDate" validCharacters="calendar" pattern="MM/DD/YYYY" (changeCallBackEvent)="setNdaExecutionDate($event)"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('firstYearOfIssuance') }">First Year</th>
                <td *ngIf="!isEdit">{{ project.firstYearOfIssuance || '-' }}</td>
                <td *ngIf="isEdit">
                    <sdk-textbox [value]="projectEdit.firstYearOfIssuance" validCharacters="custom" pattern="^([0-9]{0,4}|\s*)$" width="50px" (changeCallBackEvent)="setNumberValue('firstYearOfIssuance', { target: { value: $event } })"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('projectLife') }">Project Life</th>
                <td *ngIf="!isEdit">{{ (project.projectLife) ? project.projectLife + ' yrs' : '-' }}</td>
                <td *ngIf="isEdit">
                    <sdk-textbox style="display: inline-flex;" [value]="projectEdit.projectLife" validCharacters="custom" pattern="^[0-9]{0,3}$" width="50px" (changeCallBackEvent)="setNumberValue('projectLife', { target: { value: $event } })"></sdk-textbox><span style="margin-left: 5px;">yrs</span>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('cvxProjectType') }">Project Type</th>
                <td *ngIf="!isEdit">{{ project.cvxProjectType && project.cvxProjectType.name || '-' }}</td>
                <td *ngIf="isEdit">
                    <select class="required value" (change)="setProjectType($event)">
                        <option value="" [selected]="!projectEdit.cvxProjectType || projectEdit.cvxProjectType === ''">...</option>

                        <ng-container *ngFor="let type of projectTypeList">
                            <optgroup [label]="type.name">
                                <ng-container *ngFor="let subtype of type.cvxProjectTypes">
                                    <option [value]="type.name + ',' + subtype.id" [selected]="projectEdit.cvxProjectType?.cvxProjectScopeName === type.name && projectEdit.cvxProjectType.name === subtype.name">{{ subtype.name }}</option>
                                </ng-container>
                            </optgroup>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('product') }">Customer</th>
                <td *ngIf="!isEdit">{{ project.product && project.product.name || '-' }}</td>
                <td *ngIf="isEdit">
                    <select class="required value" (change)="setProduct($event)">
                        <option value="" [selected]="!projectEdit.product || projectEdit.product === ''">...</option>

                        <ng-container *ngFor="let product of projectProductList">
                            <option [value]="product | json" [selected]="projectEdit.product?.name === product.name">{{ product.name }}</option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('isAvoidance') }">Reduction Type</th>
                <td *ngIf="!isEdit && project.isRemoval && project.isAvoidance">Both</td>
                <td *ngIf="!isEdit && project.isRemoval && !project.isAvoidance">Removal</td>
                <td *ngIf="!isEdit && !project.isRemoval && project.isAvoidance">Avoidance</td>
                <td *ngIf="!isEdit && !project.isRemoval && !project.isAvoidance">-</td>
                <td *ngIf="isEdit">
                    <select (change)="setReductionType($event)">
                        <option value="" [selected]="!projectEdit.isRemoval && !projectEdit.isAvoidance">...</option>
                        <option value="Avoidance" [selected]="!projectEdit.isRemoval && projectEdit.isAvoidance">Avoidance</option>
                        <option value="Removal" [selected]="projectEdit.isRemoval && !projectEdit.isAvoidance">Removal</option>
                        <option value="Both" [selected]="projectEdit.isRemoval && projectEdit.isAvoidance">Both</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('investmentType') }">Investment Type</th>
                <td *ngIf="!isEdit">{{ project.investmentType && project.investmentType.name || '-' }}</td>
                <td *ngIf="isEdit">
                    <select (change)="setInvestmentType($event)">
                        <option value="" [selected]="!projectEdit.investmentType || projectEdit.investmentType === ''">...</option>

                        <ng-container *ngFor="let investment of projectInvestmentTypeList">
                            <option [value]="investment | json" [selected]="projectEdit.investmentType?.name === investment.name">{{ investment.name }}</option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('contractedLifetimeVolume') }">Contracted Lifetime Vol</th>
                <td *ngIf="!isEdit">{{ project.contractedLifetimeVolume || '-' }} MTCO2e</td>
                <td *ngIf="isEdit">
                    <sdk-textbox [value]="projectEdit.contractedLifetimeVolume" style="display: inline-flex"
                                 validCharacters="decimal"
                                 width="100px" (changeCallBackEvent)="setNumberValue('contractedLifetimeVolume', { target: { value: $event } })"></sdk-textbox> MTCO2e
                </td>
            </tr>
            <ng-container *ngIf="!isEdit">
                <tr>
                    <th scope="col">NPV / IRR</th>
                    <ng-container *ngIf="projectScreening && projectScreening.returnsMetric; else NoData">
                        <td *ngIf="projectScreening.returnsMetric.id === 1">
                            IRR
                        </td>
                        <td *ngIf="projectScreening.returnsMetric.id === 2">
                            NPV
                        </td>
                    </ng-container>
                    <ng-template #NoData>
                        <td>No Return Potential data available for this project.</td>
                    </ng-template>
                </tr>
                <tr *ngIf="projectScreening && projectScreening.returnsMetric">
                    <ng-container *ngIf="projectScreening.returnsMetric.id === 1; else Npv">
                        <th scope="col">
                            Expected IRR
                        </th>
                        <td>
                            <div>
                                <span class="label">Mid:</span>
                                {{ projectScreening.estimatedMidInternalRateOfReturn ? projectScreening.estimatedMidInternalRateOfReturn + '%' : '-' }}
                            </div>
                            <div>
                                <span class="label">Low:</span>
                                {{ projectScreening.estimatedMinimumInternalRateOfReturn ? projectScreening.estimatedMinimumInternalRateOfReturn + '%' : '-' }}
                            </div>
                            <div>
                                <span class="label">High:</span>
                                {{ projectScreening.estimatedMaximumInternalRateOfReturn ? projectScreening.estimatedMaximumInternalRateOfReturn + '%' : '-' }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-template #Npv>
                        <th scope="col">
                            Net NPV
                        </th>
                        <td>
                            <div>
                                <span class="label">Mid:</span>
                                {{ projectScreening.midNetPresentValuePerTonne ? projectScreening.midNetPresentValuePerTonne + '%' : '-' }}
                            </div>
                            <div>
                                <span class="label">Low:</span>
                                {{ projectScreening.minNetPresentValuePerTonne ? projectScreening.minNetPresentValuePerTonne + '%' : '-' }}
                            </div>
                            <div>
                                <span class="label">High:</span>
                                {{ projectScreening.maxNetPresentValuePerTonne ? projectScreening.maxNetPresentValuePerTonne + '%' : '-' }}
                            </div>
                        </td>
                    </ng-template>
                </tr>
            </ng-container>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('country') }">Country</th>
                <td *ngIf="!isEdit && project.location?.addressCountry != null">{{ project.location && project.location.addressCountry && project.location.addressCountry.name || '-' }}</td>
                <td *ngIf="!isEdit && project.isGlobalLocation">Global</td>
                <td *ngIf="isEdit">
                    <ng-container *ngIf="!projectEdit.isGlobalLocation">
                        <select (change)="setCountry($event)">
                            <option value=""
                                    [selected]="!projectEdit.location || !projectEdit.location.addressCountry || projectEdit.location.addressCountry === ''">
                                ...
                            </option>
                            <ng-container *ngFor="let country of projectCountryList">
                                <option [value]="country | json"
                                        [selected]="projectEdit.location?.addressCountry?.alpha3Code === country.alpha3Code">
                                    {{ country.name }}
                                </option>
                            </ng-container>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="projectEdit.isGlobalLocation">
                        <select (change)="setCountry($event)">
                            <ng-container *ngFor="let country of projectCountryList">
                                <option [value]="country | json" [selected]="country.id == globalLocation.id">
                                    {{ country.name }}
                                </option>
                            </ng-container>
                        </select>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('region') }">Region</th>
                <td>{{ project.location && project.location.addressCountry && project.location.addressCountry.subRegion && project.location.addressCountry.subRegion.name || '-' }}</td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('projectPortfolioId') }">Epic ID</th>
                <td *ngIf="!isEdit">
                    <span [projectPortfolioId]="project.projectPortfolioId"></span>
                </td>
                <td *ngIf="isEdit">
                    <ccms-portfolio-epic-select [initialValue]="projectEdit.projectPortfolioId"
                                                (changed)="setProjectEpicId($event)"></ccms-portfolio-epic-select>
                </td>
            </tr>
        </tbody>
    </table>

    <sdk-loading [isLoading]="isLoading"></sdk-loading>
</div>
