import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Clone } from "~/classes/shared/clone";
import { ICvxProject } from "~/models/shared/cvxproject";
import { UserInfo } from "~/models/shared/users/user-info";
import { ProjectService } from "~/services/shared/projects/project.service";


@Component({
    selector: 'project-add-contacts',
    templateUrl: './project-add-contacts.component.html',
    styleUrls: ['./project-add-contacts.component.scss']
})

export class ProjectAddContacts implements OnInit {
    public isLoading: boolean = true;
    public owners: any[] = [];

    @Input() project: any;
    @Output() changeEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<boolean> = new EventEmitter();

    public entity: string = "CvxProject";

    constructor(private projectService: ProjectService) {

    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }
    
    private async loadData(): Promise<void> {
        try {
            this.owners = Clone.deepCopy(this.project.owners);
        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isRequired(field: string): boolean {
        return this.projectService.isRequired(this.project, field);
    }

    public addOwner() {
        this.owners.push({ accountName: "", percentOwned: 0 });

        this.infoChange({ field: "owners", value: this.owners });
    }

    public deleteOwner(id: number) {
        this.owners = this.owners.filter((owner: any) => owner.accountId !== id);

        this.infoChange({ field: "owners", value: this.owners });
    }

    public ownerChange(index: number, field: any, value: any) {
        this.owners[index][field] = value;

        this.infoChange({ field: "owners", value: this.owners });
    }

    public infoChange(event: any) {
        this.project[event.field] = event.value;

        this.changeEvent.emit(this.project);
    }

    public userSelectionChange(field: string, event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const user: UserInfo = event;
            id = user.id;
            name = user.displayName;
        }
        
        this.infoChange({ field: `${field}Id`, value: id });
        this.infoChange({ field: field, value: name });
    }
}

