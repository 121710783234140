import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MetaRegistryService } from '../../../../services/shared/metaregistry.service'
import { Subscription } from 'rxjs';
import { IAlert } from '../../../../models/shared/alert.model';
import { MapsService } from '~/services/shared/maps/maps.service';
import { GeoFile, MetaRegistry } from '~/models/shared/metaregistry';
import { GeoCoordinates } from '~/components/shared/map-viewer/geo-coordinate';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { AlertType } from '~/models/shared/errors/error-alert-type.enum';
import { SDKDataGridColumn, SDKDataGridDataset } from 'sdk-datagrid';
import { FormatterService } from '~/services/shared/formatter.service';

@Component({
  selector: 'metaregistry-detail-location',
  templateUrl: './metaregistry-detail-location.component.html',
  styleUrls: ['./metaregistry-detail-location.component.scss']
})
export class MetaRegistryDetailLocationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  project!: MetaRegistry;

  public showMap: boolean = false;
  public location!: GeoCoordinates | string | null;
  public place!: string | null;
  public latitude!: number;
  public longitude!: number;
  public zoom: string = "4";
  public isLoading = false;
  public selectedShapefile: LocalMapDataSource | null = null;
  public mapChanged: boolean = false;
  public alertMessage!: string | null;
  public alertType: AlertType = AlertType.SUCCESS;

  public selectedDataSources: LocalMapDataSource[] = [];
  public shapefiles: LocalMapDataSource[] = [];
  
  public get hasShapefiles() {
     return this.shapefiles?.length > 0;
  }
  
  public showAlerts: boolean = true;
  public alerts: IAlert[] = [];
  public columns: SDKDataGridColumn[] = [
    { Name: "alertType", DisplayName: "Alert" },
    { Name: "anomalyDuration", DisplayName: "Duration (days)" },
    { Name: "date", DisplayName: "Date", Sort: "desc", formatter: (value: any) => this.dateFormatter(value) },
    { Name: "thermalAnomalies", DisplayName: "Anomalies" },
  ];
  public activeView: string = "metaregistry/alerts";
  public datasets: SDKDataGridDataset[] = [
    {
      Title: "Project Alerts",
      DbName: "metaregistry/alerts",
    }
   ];

  private subscriptions: Subscription[] = [];
  public canEditMaps: boolean = false;

  constructor(
    private metaRegistryService: MetaRegistryService,
    private mapsService: MapsService,
    private renderer: Renderer2,
    private userPermissionService: UserPermissionsService,
    private formatterService: FormatterService) 
    { 
    }

  //******************************************************************************
  //  Page Life-cycle Methods
  //******************************************************************************
  public async ngOnInit() {
    let coords: GeoCoordinates | null = null;
    if (this.project.latitude && this.project.longitude) {
      this.showMap = true;
      this.latitude = this.project.latitude;
      this.longitude = this.project.longitude;
      coords = new GeoCoordinates(this.latitude, this.longitude);
      this.location = coords;
    } 
    
    if (this.project.country) {
      this.showMap = true;
      this.place = (this.project.state && this.project.state !== "") ? this.project.state + "," + this.project.country : this.project.country;
    }
  }

  async ngAfterViewInit() {
    this.canEditMaps = await this.userPermissionService.hasAdminAccess();
  
    await this.loadMaps();

  } 

  private async loadMaps() {
    this.isLoading = true;
    const sasLink = await this.getMaps();
    const geoLinks = await this.getProvisonalMaps();
    const combinedArray = this.getCombinedArray(sasLink, geoLinks);
    await this.downloadAndAddShapefiles(combinedArray);
    if (!this.showMap) {
      this.showMap = this.shapefiles.length > 0;
    }
    this.isLoading = false;
  }

  private async getMaps(): Promise<GeoFile[] | null> {
    try {
        return await this.mapsService.getMapsForProject(this.project.id);
    } catch (error: any) {
        console.log("No primary map");
        return null;
    }
  }

  private async getProvisonalMaps(): Promise<GeoFile[] | null> {
    try {
        return await this.mapsService.getProvisionalMapsForProject(this.project.id);
    } catch (error) {
        console.error(error);
        return null;
    }
  }

  private getCombinedArray(maps: GeoFile[] | null, provisionalMaps: GeoFile[] | null): { sasLink: string, registryLink: string, name: string, isCurated: boolean, container: string }[] {
    let combinedArray: { sasLink: string, registryLink: string, name: string, isCurated: boolean, container: string}[] = [];
    if (maps && maps.length > 0) {
      combinedArray = combinedArray.concat(maps.map(link => ({ 
        sasLink: link.fileUri, 
        registryLink: link.registryUri, 
        name: link.documentDisplayName ? link.documentDisplayName : this.extractBlobName(link.fileUri), 
        isCurated: true,
        container: this.getContainerFromBlobUrl(link.fileUri)
      })));
    }
    if (provisionalMaps && provisionalMaps.length > 0) {
      combinedArray = combinedArray.concat(provisionalMaps.map(link => ({ 
        sasLink: link.fileUri, 
        registryLink: link.registryUri, 
        name: link.documentDisplayName ? link.documentDisplayName : this.extractBlobName(link.fileUri), 
        isCurated: false,
        container: this.getContainerFromBlobUrl(link.fileUri)
      })));
    }
    return combinedArray;
  }

  getContainerFromBlobUrl(blobUrl: string): string {
    const url = new URL(blobUrl);
    const pathParts = url.pathname.split('/');
    return pathParts[1];
  }

  private extractBlobName(sasLink: string): string {
    let url = new URL(sasLink);
    let blobNameWithExtension = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    let blobName = blobNameWithExtension.substring(0, blobNameWithExtension.lastIndexOf("."));
    return blobName;
  }

  private async downloadAndAddShapefiles(combinedArray: { sasLink: string, registryLink: string, name: string, isCurated: boolean, container: string }[]) {
    this.shapefiles = [];
    this.selectedDataSources = [];
    this.selectedShapefile = null;
    for (const [index, { sasLink, registryLink, name, isCurated, container }] of combinedArray.entries()) {
        try {
            const data = await this.getGeoJsonFromSas(sasLink);
            const shapefile = this.createShapefile(index, sasLink, registryLink, name, isCurated, data, container);
            this.shapefiles.push(shapefile);
            if (isCurated) {
              this.selectedDataSources = [...this.selectedDataSources, shapefile];
            }
        } catch (error) {
            console.error('Error adding map link: ', error);
        }
    }
  }

  private createShapefile(index: number, sasLink: string, registryLink: string, docName: string, isCurated: boolean, data: any, container: string) {
    return {
        id: `${index}`,
        name: docName,
        type: 'geojson',
        data: data,
        sasLink: sasLink,
        registryLink: registryLink,
        documentDisplayName: docName,
        isCurated: isCurated,
        container: container
    };
  }

  radioClicked(shapefile: LocalMapDataSource) {
    this.selectedShapefile = shapefile;
    console.log(`selected shapefile: ${shapefile.id}`);
   
    if (this.selectedShapefile?.id != shapefile.id) {
      this.mapChanged = true;
    } else {
      this.mapChanged = false;
    }
  }
  
  public promoteMap() {
    if (this.selectedShapefile) { 
        this.mapsService.promoteMap(this.project.id, this.selectedShapefile.sasLink)
        .then(async (data: any) => { 
          this.mapChanged = false;
          this.alertMessage = `Map promoted successfully: ${data}`;
          this.alertType = AlertType.SUCCESS;
          this.loadMaps();
        })
        .catch((error: any) => {
          const ccmsError = new CcmsServerError(error);
          this.alertType = AlertType.WARNING;
          this.alertMessage = `Map promotion failed:\n ${ccmsError.errors}`;
      });
    }
  }

  public demoteMap() {
    if (this.selectedShapefile) {
      this.mapsService.demoteMap(this.project.id, this.selectedShapefile.sasLink)
     .then(async (data: any) => { 
         this.mapChanged = false;
         this.alertMessage = `Map demoted successfully ${data}`;
         this.alertType = AlertType.SUCCESS;
         this.loadMaps();
     })
     .catch((error: any) => {
         const ccmsError = new CcmsServerError(error);
         this.alertType = AlertType.WARNING;
         this.alertMessage = `Map demotion failed:\n ${ccmsError.errors}`;
     });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async downloadShapefile(idx: number) {
    console.log(`downloading shapefile ${idx}`)

    const sasLink = this.shapefiles[idx].sasLink;
    if (sasLink) {
      const link = this.renderer.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', sasLink);
      link.setAttribute('download', `${this.project.id}.json`);
      link.click();
      link.remove();
    }
  }

  public isMapFileVisible(idx: number) {
    if (idx >= this.shapefiles.length) {
      console.warn("Shapefile index out of bound.");
      return;
    }

    const shapefile = this.shapefiles[idx];
    return this.selectedDataSources.includes(shapefile);
 }

  public toggleShapefile(idx: number) {
    if (idx >= this.shapefiles.length) {
      console.warn("Shapefile index out of bound.");
      return;
    }

    const shapefile = this.shapefiles[idx];
    const current = [ ...this.selectedDataSources ];
    const selectedIndex = this.selectedDataSources.indexOf(shapefile);
    if (selectedIndex > -1) {
      current.splice(selectedIndex, 1);
    }
    else {
      current.push(shapefile);
    }
    this.selectedDataSources = [ ...current ];
  }

  async getGeoJsonFromSas(saslink: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (saslink) {
        this.mapsService.downloadJsonFromSas(saslink)
          .subscribe({
            next: (data) => {
              resolve(data);
            },
            error: (error) => {
              console.error('Error downloading JSON data:', error);
              reject(error);
            }
          });
      } else {
        reject('No SAS link provided');
      }
    });
  }

  private toDms(dd, isLng) {
    const dir = this.getDirection(dd, isLng);

    const absDd = Math.abs(dd);
    let deg = Math.floor(absDd);
    const frac = absDd - deg;
    let min = Math.floor(frac * 60);
    let sec = Math.round(frac * 3600 - min * 60);

    if (sec === 60) {
      min++;
      sec = 0;
    }

    if (min === 60) {
      deg++;
      min = 0;
    }

    return deg + "°" + min + "'" + sec + '"' + dir;
  }

  private getDirection(dd, isLng): string {
    let dir;

    if (dd < 0) {
      if (isLng) {
        dir = 'W';
      } else {
        dir = 'S';
      }
    } else {
      if (isLng) {
        dir = 'E';
      } else {
        dir = 'N';
      }
    }

    return dir;
  }

  public getCoordinates(): string | null {
    if (this.latitude && this.longitude) {
      return `${this.latitude}, ${this.longitude} (${this.toDms(this.latitude, false)}, ${this.toDms(this.longitude, true)})`;
    }

    return null;
  }

  public loadData($event: any) {

    this.metaRegistryService.getAlerts(this.project.id).subscribe(data => {
      this.alerts = data;
      this.showAlerts = this.alerts.length > 0;
      console.clear();
      console.log(this.alerts);

    });
  }

  private dateFormatter(value: any): string {

    const date = new Date(value);
    const dateString = "0001-01-01T00:00:00+00:00";
    const invalid = new Date(dateString);


    if (date.getFullYear() === invalid.getFullYear()) {
      return "";
    }
    return this.formatterService.formatDate(value, "MM/dd/yyyy");
  }
}

type LocalMapDataSource = {
  id: string;
  name: string;
  data: any;
  sasLink: string;
  registryLink?: string;
  isCurated?: boolean;
  container: string;
}
