import { MethodologyDto } from "~/components/shared/methodology-selector/methodology.dto";
import { IInvestmentType, ISustainableDevelopmentGoalInput, ICreditForecast, ICustomerProduct, ITechnicalReviewStatus, ITechnicalReviewRecommendation, ICreditsMarket, IDidNotPursueReason, IMethodologyProtocol } from "~/models/shared/common";
import { ICvxProject, ICvxProjectDeveloper, ICvxProjectOwner, ICvxProjectStatus, ICvxProjectType, IWsjfScorecard, ProjectConstants } from "~/models/shared/cvxproject";
import { ICountry, IGeoCoordinate, IPostalAddress } from "~/models/shared/location";
import { ProjectPriorityScoreCard } from "~/services/shared/projects/models/project-priority-score-card";

export class NewProject implements ICvxProject {
    id: number = 0;
    contact: string = '';
    createdOn: Date = new Date();
    modifiedOn: Date = new Date();
    description: string = '';
    cvxProjectDeveloper!: ICvxProjectDeveloper;
    executionStartDate: Date | null = null;
    ndaExecutionDate: Date | null = null;
    firstYearOfIssuance: number = 0;
    fundingOrSpending: number = 0;
    investmentType: IInvestmentType = {} as IInvestmentType;
    isArticle6Eligible: boolean = false;
    isAvoidance: boolean = false;
    isRemoval: boolean = false;
    location: IPostalAddress = {} as IPostalAddress;
    country?: ICountry = {} as ICountry;
    metaRegistryProjectId: string = '';
    methodologyId: number | null = null;
    methodology: MethodologyDto = {} as MethodologyDto;
    methodologyProtocol: IMethodologyProtocol = {} as IMethodologyProtocol;
    name: string = '';
    cvxProjectType: ICvxProjectType = {} as ICvxProjectType;
    operator: string = '';
    owners: ICvxProjectOwner[] = [];
    site: IGeoCoordinate = {} as IGeoCoordinate;
    status: ICvxProjectStatus = {} as ICvxProjectStatus;
    sustainableDevelopmentGoals: ISustainableDevelopmentGoalInput[] = [];
    totalCreditsForecast: number = 0;
    totalSpend: number = 0;
    verifier: string = '';
    yearlyCreditForecasts: ICreditForecast[] = [];
    yearlyCreditForecastsFunded: ICreditForecast[] = [];
    yearlyCreditForecastsCurrent: ICreditForecast[] = [];
    didNotPursueReason: IDidNotPursueReason = {} as IDidNotPursueReason;
    didNotPursueReasonOther: string = '';
    creditsMarket: ICreditsMarket = {} as ICreditsMarket;
    barriers: string = '';
    priority: number = 0;
    projectStatusNotes: string = '';
    originator: string = '';
    legalLead: string = '';
    notes: string = '';
    product: ICustomerProduct = {} as ICustomerProduct;
    projectPortfolioId: string = '';
    termSheetLead?: string;
    negotiator?: string;
    contractedLifetimeVolume?: number;
    maximumAccessLifetimeVolume?: number;
    projectLife?: number;
    annualVolume?: number;
    technicalLead?: string;
    technicalReviewNotes?: string;
    technicalReviewCompletionDate?: Date;
    technicalReviewStatus?: ITechnicalReviewStatus;
    technicalReviewRecommendation?: ITechnicalReviewRecommendation;
    lastUpdated: Date = new Date();
    eligibility?: string;
    carbon?: string;
    integrity?: string;
    feasibility?: string;
    wsjfScorecard?: IWsjfScorecard;
    wsjfScorecardTimeCriticality?: IWsjfScorecard;
    wsjfScorecardBusinessValue?: IWsjfScorecard;
    wsjfRiskReductionValue?: IWsjfScorecard;
    wsjfEstimatedEffort?: IWsjfScorecard;
    wsjfScore?: IWsjfScorecard;
    isGlobalLocation?: boolean;
    externalTechResource?: string;
    priorityScoreCard: ProjectPriorityScoreCard = {} as ProjectPriorityScoreCard;
    projectTypeRating?: number;
    geographyRating?: number;
    developerRating?: number;
    fungibilityRating?: number;
    scaleRating?: number;
    economicsRating?: number;
    initialScreeningPriority?: number;
    priorityWithEconomics?: number;

}  
