<div class="content">
    <sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>

    <div class="header">
        <div class="title">{{ title }}</div>
    </div>

    <div class="options">
        <div *ngIf="canCreate" title="Add New Project" class="icon material-icons-outlined" (click)="addNewProject()">add_notes</div>
        <div *ngIf="chartsAuthorized" title="Show Grid" class="icon material-icons-outlined" [ngClass]="{ active: gridVisible }" (click)="setView('grid')">view_list</div>
        <div *ngIf="chartsAuthorized" title="Show Charts" class="icon material-icons-outlined" [ngClass]="{ active: chartsVisible }" (click)="setView('charts')">insert_chart</div>
    </div>

    <div class="layouts" [ngClass]="{ grid: gridVisible }">
        <project-grid *ngIf="gridVisible" (setMessageEvent)="setMessage($event)" #projectGrid></project-grid>
        <project-charts *ngIf="chartsAuthorized && chartsVisible" (setMessageEvent)="setMessage($event)"></project-charts>
    </div>
</div>
