<div *ngIf="!isLoading && !project" class="nodata">No Ratings data available for this project.</div>
<div *ngIf="!isLoading && project" class="data">
    <div class="sections">
        <div class="header">Summary</div>
        <table class="table-x centered">
            <caption style="display: none;">Location</caption>
            <tbody>
                <tr>
                    <th scope="col">
                        <div class="header-info">
                            <span class="label">Sylvera</span>
                            <span [title]="getLabelHoverText('Sylvera', 'header')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <th scope="col">
                        <div class="header-info">
                            <span class="label">Calyx</span>
                            <span [title]="getLabelHoverText('Calyx', 'header')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <th scope="col">
                        <div class="header-info">
                            <span class="label">BeZero</span>
                            <span [title]="getLabelHoverText('BeZero', 'header')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td [title]="getRatingHoverText('Sylvera', 'rating', project.sylveraRating)">
                        <a *ngIf="project.sylveraRatingsUrl" [href]="project.sylveraRatingsUrl" target="_blank">
                            {{ project.sylveraRating }}{{project.isProvisionalSylveraRating ? '*' : ''}}
                        </a>
                        <div *ngIf="!project.sylveraRatingsUrl">{{ project.sylveraRating }}{{project.isProvisionalSylveraRating ? '*' : null || '-' }}</div>
                    </td>
                    <td [title]="getRatingHoverText('Calyx', 'rating', project.calyxGhgRating)">{{ project.calyxGhgRating || '-' }}</td>
                <td>
                    <div class="rating-info" [title]="project.beZeroRatingDetails && project.beZeroRatingDetails.summaryAnalysis? project.beZeroRatingDetails.summaryAnalysis : getRatingHoverText('BeZero', 'rating', project.beZeroRating)">
                        {{ project.beZeroRating || '-' }} {{ (project.beZeroRatingDetails && project.beZeroRatingDetails.isOnWatch) ? '(' + 'rw' + ')' : '' }}
                    </div>
                </td>
                </tr>
            </tbody>
        </table>
    
        <div class="header top">Details</div>
        <table class="table-x centered">
            <caption style="display: none;">Details</caption>
            <tbody>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Sylvera</th>
                    <th scope="col">Calyx</th>
                    <th scope="col">BeZero</th>
                </tr>
                <tr>
                    <th scope="row" style="text-align: left;">
                        <div class="header-info">
                            <span class="label">Additionality</span>
                            <span [title]="getLabelHoverText('common', 'additionality')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <td [title]="getRatingHoverText('Sylvera', 'additionality', project.sylveraAdditionality)">{{ convertRating(project.sylveraAdditionality) || '-' }}</td>
                    <td [title]="getRatingHoverText('Calyx', 'additionality', (project.calyxProject) ? project.calyxProject.additionality : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.additionality) || '-' : '-' }}</td>
                    <td [title]="getRatingHoverText('BeZeroRisk', 'additionality', getBezeroRating(project.beZeroRatingDetails, 'Additionality'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Additionality') || '-' : '-' }}</td>
                </tr>
                <tr>
                    <th scope="row" style="text-align: left;">
                        <div class="header-info">
                            <span class="label">Permanence</span>
                            <span [title]="getLabelHoverText('common', 'permanence')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <td [title]="getRatingHoverText('Sylvera', 'permanence', project.sylveraPermanence)">{{ convertRating(project.sylveraPermanence) || '-' }}</td>
                    <td [title]="getRatingHoverText('Calyx', 'permanence', (project.calyxProject) ? project.calyxProject.permanence : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.permanence) || '-' : '-' }}</td>
                    <td [title]="getRatingHoverText('BeZeroRisk', 'permanence', getBezeroRating(project.beZeroRatingDetails, 'Non-permanence'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Non-permanence') || '-' : '-' }}</td>
                </tr>
                <tr>
                    <th scope="row" style="text-align: left;">
                        <div class="header-info">
                            <span class="label">Co-Benefits</span>
                            <span [title]="getLabelHoverText('common', 'co-benefits')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <td [title]="getRatingHoverText('Sylvera', 'co-benefits', project.sylveraCoBenefits)">{{ convertRating(project.sylveraCoBenefits) || '-' }}</td>
                    <td [title]="getRatingHoverText('Calyx', 'co-benefits', '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.sdgImpactRating) || '-' : '-' }}</td>
                    <td [title]="getRatingHoverText('BeZeroRisk', 'co-benefits', '')">-</td>
                </tr>
                <tr>
                    <th scope="row" style="text-align: left;">
                        <div class="header-info">
                            <span class="label">Performance</span>
                            <span [title]="getLabelHoverText('common', 'performance')" class="icon material-icons-outlined">info</span>
                        </div>
                    </th>
                    <td>
                        <table class="table-x-no-lines">
                            <caption style="display: none;">Carbon</caption>
                            <tbody>
                                <tr>
                                    <th [title]="getLabelHoverText('Sylvera', 'carbon-score')" style="text-align: left;">Carbon Score</th>
                                    <td [title]="project.sylveraCarbonScore ? (project.sylveraCarbonScore * 100) + '%' : '-'">{{ convertSylveraCarbonScore(project.sylveraCarbonScore) || '-' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table class="table-x-no-lines">
                            <caption style="display: none;">Baseline</caption>
                            <tbody>
                                <tr>
                                    <th [title]="getLabelHoverText('Calyx', 'leakage')" style="text-align: left;">Leakage</th>
                                    <td [title]="getRatingHoverText('Calyx', 'leakage', (project.calyxProject) ? project.calyxProject.leakage : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.leakage) || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('Calyx', 'baseline')" style="text-align: left;">Baseline</th>
                                    <td [title]="getRatingHoverText('Calyx', 'baseline', (project.calyxProject) ? project.calyxProject.baseline : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.baseline) || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('Calyx', 'emissions')" style="text-align: left;">Emissions</th>
                                    <td [title]="getRatingHoverText('Calyx', 'emissions', (project.calyxProject) ? project.calyxProject.projectEmissions : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.projectEmissions) || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('Calyx', 'overlapping-claims')" style="text-align: left;">Overlapping Claims</th>
                                    <td [title]="getRatingHoverText('Calyx', 'overlapping-claims', (project.calyxProject) ? project.calyxProject.overlappingClaims : '')">{{ (project.calyxProject) ? convertRating(project.calyxProject.overlappingClaims) || '-' : '-' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td style="text-align: left;">
                        <table class="table-x-no-lines">
                            <caption style="display: none;">Leakage</caption>
                            <tbody>
                                <tr>
                                    <th [title]="getLabelHoverText('BeZero', 'leakage')" style="text-align: left;">Leakage</th>
                                    <td [title]="getRatingHoverText('BeZeroRisk', 'leakage', getBezeroRating(project.beZeroRatingDetails, 'Leakage'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Leakage') || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('BeZero', 'incentives')" style="text-align: left;">Incentives</th>
                                    <td [title]="getRatingHoverText('BeZeroRisk', 'incentives', getBezeroRating(project.beZeroRatingDetails, 'Perverse Incentives'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Perverse Incentives') || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('BeZero', 'over-crediting')" style="text-align: left;">Over Crediting</th>
                                    <td [title]="getRatingHoverText('BeZeroRisk', 'over-crediting', getBezeroRating(project.beZeroRatingDetails, 'Over-crediting'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Over-crediting') || '-' : '-' }}</td>
                                </tr>
                                <tr>
                                    <th [title]="getLabelHoverText('BeZero', 'policy')" style="text-align: left;">Policy</th>
                                    <td [title]="getRatingHoverText('BeZeroRisk', 'policy', getBezeroRating(project.beZeroRatingDetails, 'Political/Policy'))">{{ (project.beZeroRatingDetails) ? convertBezeroRating(project.beZeroRatingDetails, 'Political/Policy') || '-' : '-' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <div class="header top">Country Feasibility Score: <span>{{ (project.countryRisk) ? project.countryRisk.overallFeasibility + '%' || '-' : '-' }}</span></div>
        <table *ngIf="project.countryRisk" class="table-x centered">
            <caption style="display: none;">Country</caption>
            <tbody>
                <tr>
                    <th scope="col">Economic</th>
                    <th scope="col">Political</th>
                    <th scope="col">Geophysical</th>
                    <th scope="col">Technical</th>
                    <th scope="col">Socio-Cultural</th>
                    <th scope="col">Environmental</th>
                </tr>
                <tr>
                    <td>{{ (project.countryRisk.economic) ? project.countryRisk.economic +'%' || '-' : '-' }}</td>
                    <td>{{ (project.countryRisk.political) ? project.countryRisk.political +'%' || '-' : '-' }}</td>
                    <td>{{ (project.countryRisk.geophysical) ? project.countryRisk.geophysical +'%' || '-' : '-' }}</td>
                    <td>{{ (project.countryRisk.technical) ? project.countryRisk.technical +'%' || '-' : '-' }}</td>
                    <td>{{ (project.countryRisk.socioCultural) ? project.countryRisk.socioCultural +'%' || '-' : '-' }}</td>
                    <td>{{ (project.countryRisk.environmental) ? project.countryRisk.environmental +'%' || '-' : '-' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="!isLoading && project" class="info">
    <table class="table-x">
        <caption style="display: none;">Ratings</caption>
        <tbody>
            <tr>
                <th scope="col">Project ID:</th>
                <td [title]="project.id || '-'">{{ project.id || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Status:</th>
                <td>{{ project.status || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Registry:</th>
                <td><a [href]="project.registryUrl" target="_blank">{{ project.registry }}</a></td>
            </tr>
            <tr>
                <th scope="col">Country:</th>
                <td>{{ project.country || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Developer:</th>
                <td>{{ project.developer || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Project Type:</th>
                <td>{{ ((project.projectType) ? project.projectType.name : null) || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Scope:</th>
                <td>{{ project.scope || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Methodology:</th>
                <td>{{ project.protocol || '-' }}</td>
            </tr>
            <tr>
                <th scope="col">Country:</th>
                <td>{{ project.country || '-' }}</td>
            </tr>
        </tbody>
    </table>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>