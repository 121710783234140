import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { ICvxProject } from "~/models/shared/cvxproject";
import { ProjectAddInfo } from "./info/project-add-info.component";
import { ProjectAddNotes } from "./notes/project-add-notes.component";
import { ProjectAddGoals } from "./goals/project-add-goals.component";
import { ProjectService } from "~/services/shared/projects/project.service";
import { NewProject } from "./models/project-add-model";
import { MatStepper } from "@angular/material/stepper";
import { ProjectAddContacts } from "./contacts/project-add-contacts.component";
import { ProjectAddLocation } from "./location/project-add-location.component";
import { ProjectAddTechReview } from "./tech-review/project-add-tech-review.component";
import CcmsServerError from "~/models/shared/errors/ccms-server-error";
import { AlertType } from "~/models/shared/errors/error-alert-type.enum";
import { AppComponent } from "~/app.component";

@Component({
    selector: 'project-add',
    templateUrl: './project-add.component.html',
    styleUrls: ['./project-add.component.scss']
})

export class ProjectAddComponent implements AfterViewInit {
    @Output() continueEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild("projectWizard") projectWizard!: MatStepper;

    public isLoading: boolean = true;
    public disabled: boolean = true;

    public steps: any[] = [
        { type: <any>ProjectAddInfo , title: "Project Info"},
        { type: <any>ProjectAddNotes, title: "Notes"},
        { type: <any>ProjectAddGoals, title: "Sustainable Development Goals" },
        { type: <any>ProjectAddContacts, title: "Contacts" },
        { type: <any>ProjectAddLocation, title: "Location" },
        { type: <any>ProjectAddTechReview, title: "Tech Review" },
    ];
    public alertTitle: string = "Error saving Project Data";
    public alertMessage!: string | null;
    public alertContinueText: string = "OK";
    public alertCancelText: string = "";
    AT = AlertType;
    
    private newProject: NewProject = new NewProject();
    public inputs = {
        'project': this.newProject
    };
    public outputs = {
        'changeEvent': (event: any) => {
            this.disabled = !this.projectService.isValid(this.newProject);
        },
        'saveDataEvent': ({ success, step }) => {
            if (success) {
                this.createProject(this.newProject);
            }
            else {
                console.log("Step initialized");
                console.log(step);
            }
        }
    };

    constructor(
        private app: AppComponent,
        private projectService: ProjectService
    ) { }

    ngAfterViewInit(): void {
        this.isLoading = false;
    }

    public close(success: boolean = false): void {
        this.closeEvent.emit(success);
    }

    public save(): void {
        this.alertMessage = null;
        if (this.projectService.isValid(this.newProject)) {
            this.createProject(this.newProject);
        }
        else {
            this.alertMessage = "Required fileds missing"
            this.isLoading = false;
            this.projectWizard.selectedIndex = 0;
        }
    }

    private async createProject(newProject: any): Promise<void> {
        try {
            await this.projectService.saveProject(newProject)
                .then((result) => {
                    if (result.warnings && result.warnings.length > 0) {
                        this.app.displayAlert("Project Created with Warnings", result.warnings.join(" "));
                    }
                    this.close(true);
                })
            .catch((error: any) => {
              this.displayError(error);
            });
        }
        catch (error: any) {
          this.displayError(error);
        }
    }

    private displayError(error: any) {
      const ccmsError = new CcmsServerError(error);
      this.alertMessage = ccmsError.errors
    }
}


