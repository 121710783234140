import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AppSettingsService } from '~/services/core/appsettings.service';
import { AppSettings } from '~/models/core/appsettings';

import { routes } from '~/classes/core/routes/app.routing';
import { HttpService } from '~/services/core/http.service';

export class NavItem {
    public title: string = "";
    public icon: string = "";
    public Id?: string = "";
    public Count?: number = 0;
    public url: string = "";
    public Selected?: boolean = false;
    public roles?: string[] = [];
    public visible?: boolean = false;
}

@Component({
    selector: 'nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})

export class NavComponent {
    @Input() expand: boolean = false;
    @Output() setLayoutClass = new EventEmitter();
    @Output() reloadPage = new EventEmitter();
    @Output() showSearch: EventEmitter<boolean> = new EventEmitter();

    public appIcon: string = "web_asset";
    public appTitle: string = "Application Mode";

    public menuIcon: string = "keyboard_double_arrow_right";
    public activeItem: NavItem = new NavItem();

    public navItems: any = [];
    public navSubItems: any = [];

    private _routes = [...routes];
    private collapse: boolean = false;
    private search: boolean = false;
    public displaySubMenu: boolean = false;

    constructor(
        private router: Router,
        private appSettingsService: AppSettingsService,
        private httpService: HttpService
    ) { }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await this.getRoutes();

        if (this.router?.url) {
            this.setActiveItem(this.navItems, this.router.url);
            this.setActiveItem(this.navSubItems, this.router.url);
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public toggleMenu(collapse: boolean = false) {
        this.collapse = collapse;
        this.menuIcon = this.menuIcon === "keyboard_double_arrow_right" ? "keyboard_double_arrow_left" : "keyboard_double_arrow_right";

        this.setLayoutClass.emit();
    }

    public navigateTo(item: NavItem) {
        let allowNavigate: boolean = true;
        let storage = sessionStorage.getItem("__allowNavigate__");

        if (storage && storage === "false") {
            allowNavigate = confirm('WARNING: Changes have been made to this process. Are you sure you want to leave without saving these changes?');
        }

        if (this.collapse) {
            this.collapse = false;
            this.setLayoutClass.emit();
        }

        if (allowNavigate) {
            this.router.navigateByUrl(item.url);
            this.activeItem = item;
        }
    }

    public toggleFullscreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            this.appIcon = "iframe";
            this.appTitle = "Web Mode";
        } else {
            document.exitFullscreen();
            this.appIcon = "web_asset";
            this.appTitle = "Application Mode";
        }
    }

    public toggleSearch() {
      this.search = !this.search;
      this.showSearch.emit(this.search);
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getRoutes() {
        this.navItems = [];
        this.navSubItems = [];

        await this.appSettingsService.getSettings().then((appSettings: AppSettings) => {
            this._routes.forEach(async (route: any) => {
                if (route.isNav) {
                    let addItem: boolean = false;

                    if (route.roles) {
                        if (route.roles.some((r: any) => appSettings.roles.includes(r))) {
                            addItem = true;
                        } else if (route.navAuthorizer) {
                            const authorized = await route.navAuthorizer.isAuthorized(this.httpService);
                            addItem = authorized;
                        }
                    } else {
                        addItem = true;
                    }

                    if (addItem) {
                        let subRoutes: any[] = [];
                        subRoutes = this._routes.filter(element => element?.isSubNav && element?.parentId == route?.id)
                            .map(element => ({ "title": element.title, "icon": element.icon, "url": element.path, "parentId": element.parentId, "order": element?.order }));
                        subRoutes.sort((a, b) => a.order - b.order);
                        this.navItems.push(
                            {
                                "title": route.title,
                                "icon": route.icon,
                                "url": route.path,
                                "id": route?.id,
                                "subRoutes": subRoutes

                            }
                        )

                       
                    }
                }
            })
        });
    }

    private getSubRoutes(parent: any, roles: any) {
        this._routes.forEach((route: any) => {
            if (route.isSubNav) {
                let addItem: boolean = false;

                if (route.roles) {
                    if (route.roles.some((r: any) => roles.includes(r))) {
                        addItem = true;
                    }
                } else {
                    addItem = true;
                }

                if (addItem) {
                    this.navSubItems.push(
                        {
                            "title": route.title,
                            "icon": route.icon,
                            "url": route.path,
                            "parentId": route.parentId

                        }
                    )
                }
            }
        });
    }

    private setActiveItem(items: any, routeUrl: any) {
        for (let item of items) {
            if (`/${item.url}` === routeUrl) {
                this.activeItem = item;
                break;
            }
        }
    }
    public debug(message: any) {
        console.log(message);
    }
    public showSubMenu(event: any) {
        console.log(event);
        this.displaySubMenu = !this.displaySubMenu;
    }
}
