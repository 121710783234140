<div>
    <table class="table-x">
        <caption style="display: none;">ProjectInfo</caption>
        <tbody>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('name') }">Name</th>
                <td>
                    <sdk-textbox (changeCallBackEvent)="setModelValue('name', $event)"></sdk-textbox>
                </td>
            </tr>

            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('status') }">Status</th>
                <td style="position: relative;">
                    <select class="required value" (change)="setSelectModelValue('status',$event)">
                        <optgroup *ngFor="let category of projectStatusCategoryList" label="{{category}}">
                            <ng-container *ngFor="let status of projectStatusList">
                                <option *ngIf="category === status.category" [value]="status | json" [selected]="status.id == 13">{{ status.name }}</option>
                            </ng-container>
                        </optgroup>
                    </select>
                </td>
            </tr>
            <tr *ngIf="showStatusExt">
                <th scope="col" [ngClass]="{ 'required label': isRequired('didNotPursueReason') }">"Did Not Pursue" Reason</th>
                <td>
                    <select class="required value" (change)="setSelectModelValue('didNotPursueReason',$event)">
                        <option value="{{emptyDidNotPursueReason}}" [selected]="true">
                            ...
                        </option>
                        <ng-container *ngFor="let status of doNotPursueList">
                            <option [value]="status | json">
                                {{
                                status.name
                                }}
                            </option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr *ngIf="doNotPursueOtherReason">
                <th scope="col" [ngClass]="{ 'required label': true }">"Other" Reason</th>
                <td>
                    <textarea class="edit" rows="5" cols="20"
                              (input)="setModelValue('didNotPursueReasonOther', $event.target.value)"></textarea>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('priority') }">ADO Priority</th>
                <td>
                    <select class="required value" (change)="setSelectModelValue('priority',$event)">
                        <option value="0" [selected]="true">...</option>
                        <ng-container *ngFor="let priority of priorityList">
                            <option [value]="priority">{{ priority }}</option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('executionStartDate') }">Start Date</th>
                <td>
                    <sdk-textbox validCharacters="calendar" pattern="MM/DD/YYYY"
                                 (changeCallBackEvent)="setExecutionStartDate($event)"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('ndaExecutionDate') }">NDA Execution Date</th>
                <td>
                    <sdk-textbox validCharacters="calendar" pattern="MM/DD/YYYY"
                                 (changeCallBackEvent)="setNdaExecutionDate($event)"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('firstYearOfIssuance') }">First Year</th>
                <td>
                    <sdk-textbox validCharacters="custom" pattern="^([0-9]{0,4}|\s*)$" width="50px" [value]="this.project.firstYearOfIssuance"
                                 (changeCallBackEvent)="setModelValue('firstYearOfIssuance', $event)"></sdk-textbox>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('projectLife') }">Project Life</th>

                <td>
                    <sdk-textbox validCharacters="custom" pattern="^[0-9]{0,3}$" width="50px" style="display: inline-flex;"
                                 (changeCallBackEvent)="setModelValue('projectLife', $event)"></sdk-textbox><span style="margin-left: 5px;">yrs</span>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('cvxProjectType') }">Project Type</th>
                <td>
                    <select class="required value" (change)="setSelectModelValue('cvxProjectType',$event)">
                        <option value="" [selected]="true">
                            ...
                        </option>

                        <ng-container *ngFor="let type of projectTypeList">
                            <optgroup [label]="type.name">
                                <ng-container *ngFor="let subtype of type.cvxProjectTypes">
                                    <option [value]="subtype | json">
                                        {{ subtype.name }}
                                    </option>
                                </ng-container>
                            </optgroup>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('product') }">Customer</th>
                <td>
                    <select class="required value" (change)="setSelectModelValue('product',$event)">
                        <option value="" [selected]="true">...</option>

                        <ng-container *ngFor="let product of productList">
                            <option [value]="product | json">
                                {{
                                product.name
                                }}
                            </option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('reductionType') }">Reduction Type</th>
                <td>
                    <select (change)="setReductionType($event)">
                        <option value="" [selected]="true">...</option>
                        <option value="Avoidance">
                            Avoidance
                        </option>
                        <option value="Removal">
                            Removal
                        </option>
                        <option value="Both">Both</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('investmentType') }">Investment Type</th>
                <td>
                    <select (change)="setSelectModelValue('investmentType', $event)">
                        <option value="" [selected]="true">
                            ...
                        </option>

                        <ng-container *ngFor="let investment of investmentTypeList">
                            <option [value]="investment | json">
                                {{ investment.name }}
                            </option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('country') }">Country</th>

                <td>
                    <select (change)="setCountry($event)">
                        <option value="" [selected]="true">
                            ...
                        </option>

                        <ng-container *ngFor="let country of countryList">
                            <option [value]="country | json">
                                {{
                                country.name
                                }}
                            </option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('contact') }">Commercial Mgr</th>
                <td>
                    <user-selection [entity]="'CvxProject'" [field]="'Contact'" (userSelectedEvent)="setContact($event)"></user-selection>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('cvxProjectDeveloper') }">Developer</th>

                <td>
                    <developer-search (developerSelectedEvent)="setModelValue('cvxProjectDeveloper', $event)"> </developer-search>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('methodology') }">Methodology</th>
                <td>
                    <ccms-methodology-selector (methodologySelected)="onMethodologySelected($event)"></ccms-methodology-selector>
                </td>
            </tr>
            <tr>
                <th scope="col" [ngClass]="{ 'required label': isRequired('description') }">
                    Description
                </th>
                <td>
                    <div class="description">
                        <div class="description-data">
                            <textarea class="edit required value" rows="3" [attr.maxlength]="maxDescriptionLength" (input)="setModelValue('description', $event.target.value)"></textarea>
                        </div>
                        <div class="counter">
                            <counter [current]="project && project.description? project.description.length : 0" [max]="maxDescriptionLength"></counter>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <sdk-loading [isLoading]="isLoading"></sdk-loading>
</div>