import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { FormatterService } from '~/services/shared/formatter.service';

import { RequestParameters } from '~/models/shared/request-parameters';

import { SDKDataGridDataset, SDKDataGridOptions, Filters, SDKDataGridSettings } from 'sdk-datagrid';
import { BaseComponent } from '~/pages/base.component';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';

@Component({
	selector: 'metaregistry-detail-credits',
	templateUrl: './metaregistry-detail-credits.component.html',
	styleUrls: ['./metaregistry-detail-credits.component.scss']
})

export class MetaRegistryDetailCreditsComponent extends BaseComponent implements OnInit, SettingsGrid {
	@Input() project: any;
	@ViewChild('eligibilityTemplate') eligibilityTemplate!: TemplateRef<any>;
	

	public isLoading: boolean = true;
	public activeView: string = "Issuance";
	public datasets: SDKDataGridDataset[] = [
		{
			Title: "Issuance",
			DbName: "Issuance",
		}
	];
	public columns: any[] = [
		{ Name: "registry", DisplayName: "Registry" },
		{ Name: "id", DisplayName: "ID" },
		{ Name: "name", DisplayName: "Name" },
    	{ Name: "productType", DisplayName: "ProductType" },
		{ Name: "vintage", DisplayName: "Vintage", isVisible: true },
		{ Name: "quantity", DisplayName: "Quantity", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
		{ Name: "action", DisplayName: "Action", isVisible: true },
		{ Name: "eligibility", DisplayName: "Program Eligibility", isVisible: true, dataTemplate: () => this.eligibilityTemplate },
		{ Name: "certification", DisplayName: "Certification", isVisible: true},
		{ Name: "serialNumber", DisplayName: "Serial Number" },
		{ Name: "issuanceDate", DisplayName: "Issuance Date", isVisible: true, formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy") },
		{ Name: "retirementDate", DisplayName: "Retirement Date", isVisible: true, formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy") },
		{ Name: "projectType", DisplayName: "Project Type", isVisible: true },
		{ Name: "notes", DisplayName: "Notes" },
		{ Name: "retiredFor", DisplayName: "Retired For" },
	];

	// Settings grid interface Implementation:
	public uniqueIdentifier = "metaregistry.metaregistry-detail.credits.metaregistry-detail-credits";
	public allSettings: SDKDataGridSettings[] = [];
	public errorHanlder = (message, error) => {
		console.error(`${message} ${error?.message}`);
	}

	public gridOptions: SDKDataGridOptions = {
		settings: true,
		columnSettings: true,
		filtering: true,
		sorting: true,
		formulas: true,
		charts: false,
		export: true,
		expandableRows: false,
		selectableRows: false,
		autoClosePanel: true
	};
	public filterTypes: any = [Filters.Equals, Filters.Contains];

	public data: string = "";
	public fullData: string = "";
	public forceReload: boolean = false;
	public MAXRECORDS: number = 1000;
	public dbPage: number = 0;
	public dbTotal: number = 0;
	public error: string = "";

	public type: string = "";
	public typeName: string = "Graph";

	constructor(
        private metaRegistryService: MetaRegistryService,
		private formatterService: FormatterService,
		private gridHandlerService: GridHandlerService
	) {
    super();
   }

	//******************************************************************************
	//  Page Life-cycle Methods
	//******************************************************************************
	public async ngOnInit() {
		this.loadData({ chart: true });
		this.gridHandlerService.loadSettings(this);
	}

	//******************************************************************************
	//  Public Methods
	//******************************************************************************
	public async loadData(event: any = null) {
		this.isLoading = true;

		let parameters = this.buildParameters(event);
		let fullLoad: boolean = false;

		if (event && (event.chart !== undefined || event.export !== undefined)) {
			fullLoad = true;

			parameters.Paging = {
				pageSize: 999999,
				currentPage: 1
			};
		}

		try {
            await this.metaRegistryService.getIssuances(this.project.id, parameters).then((data: any) => {
				if (data) {
                    if (fullLoad) {
                        this.fullData = data.values;
                    } else {
						this.resetVariables();

                        this.data = data.values;
                        this.dbPage = parseInt(data.currentPage);
                        this.dbTotal = parseInt(data.totalCount);
                        this.error = "";
                    }
                }
			});
		} catch (error: any) {
			this.error = error.message;
		}

		this.forceReload = false;
		this.isLoading = false;
	}

	public saveSettings(event: SDKDataGridSettings[]) {
		this.gridHandlerService.saveSettings(this, event);
	}

	public getEligibility() {
		const seen = new Set<string>();

		if (this.project.programs.length > 0) {
			this.project.programs.forEach(program => {
				seen.add(program.id);
			});
		}
		for (const credit of this.project.creditsEligibility) {
			if (!seen.has(credit)) {
				seen.add(credit);
			}
		}

		return [...seen].join(', ');
	}
	//******************************************************************************
	//  Private Methods
	//******************************************************************************
	private buildParameters(event: any = null): any {
		let parameters = new RequestParameters();

		parameters.Paging = {
			pageSize: this.MAXRECORDS,
			currentPage: 1
		};

		parameters.SortingOptions = [];
		parameters.ActionFilter = this.type;

		if (event) {
			this.buildParametersPage(event, parameters);
			this.buildParametersRows(event, parameters);
			this.buildParametersSorts(event, parameters);
			this.buildParametersFilters(event, parameters);
		}

		return parameters;
	}

	private buildParametersPage(event: any, parameters: any) {
		if (event.page) {
			parameters.Paging.currentPage = parseInt(event.page);
		}
	}

	private buildParametersRows(event: any, parameters: any) {
		parameters.Paging.pageSize = this.MAXRECORDS;
	}

	private buildParametersSorts(event: any, parameters: any) {
		if (event.sorts) {
			event.sorts.forEach((sort: any, index: number) => {
				let direction = (sort.Sort === "asc") ? 0 : 1;
				let ndx = index + 1;

				parameters.SortingOptions.push({ field: sort.Name, direction: direction, priority: ndx });
			});
		}
	}

	private buildParametersFilters(event: any, parameters: any) {
		if (event.filters) {
			event.filters.forEach((filter: any) => {
				parameters.Terms.push({ Field: filter.Name, Value: filter.Value });
			});
		}
	}

	private resetVariables() {
		this.data = "";
		this.fullData = "";
		this.dbPage = 0;
		this.dbTotal = 0;
		this.error = "";
	}
}
