import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Variables } from '~/classes/core/user/variables';

import { BaseComponent } from '~/pages/base.component';

import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { ProjectAddComponent } from './components/project-add/project-add.component';
import { AppComponent } from '~/app.component';
import { ProjectFeatures, ProjectsAuthorizer } from '~/models/shared/projects/projects-authorizer';
import { HttpService } from '~/services/core/http.service';

@Component({
	selector: 'projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent extends BaseComponent {
	public title: string = "Carbon Offset Projects";

	public canCreate = false;

	public gridVisible = false;
	public cardsVisible = false;
	public chartsVisible = false;
	public chartsAuthorized = false;
	public showNewProject: boolean = false;

	public message: string = "";
	public messageStyle: string = "";
	@ViewChild('projectGrid') grid: any;
	
	constructor(
		private app: AppComponent,
		private route: ActivatedRoute,
		private userPermissionService: UserPermissionsService,
		private httpService: HttpService
	) {
		super();
	}

	//*************************************************************************
	//  Component Life-Cycle Methods
	//*************************************************************************
	public async ngOnInit() {
		await super.ngOnInit();
		await this.getPermissions();

		this.CheckChartsAccess().then((authorized) => {
			this.chartsAuthorized = authorized;
			if (authorized) {
				this.route.queryParams.subscribe((params: any) => {
					if (params?.view) {
						this.setView(params.view);
					} else {
						this.setView(Variables.get("Project.View"));
					}
				});
			} else {
				this.setView("grid");
			}
		});

		

		this.isLoading = false;
	}

	//******************************************************************************
	//  Public Methods
	//******************************************************************************
	public setView(view?: string) {
		if (!view) {
			view = "grid";
		}

		this.gridVisible = false;
		this.cardsVisible = false;
		this.chartsVisible = false;

		switch (view) {
			case "grid":
				this.gridVisible = true;
				break;

			case "cards":
				this.cardsVisible = true;
				break;

			case "charts":
				this.chartsVisible = true;
				break;
		}

		Variables.set("Project.View", view);
	}

	public async addNewProject() {
		this.app.modal = {
			type: <any>ProjectAddComponent,
			inputs: {},
			outputs: {
                'closeEvent': (event: any) => {
					this.app.modal = undefined;
					if (event) {
						this.setMessage({ text: `Project created.`, style: "color: white; background-color: rgb(118, 146, 49);" });
						this.grid.loadData(this.grid.currentEvent);
					}
        }
			}
		};
	}

	public setMessage(event: any) {
		this.message = event.text;
		this.messageStyle = event.style;
	}

	//******************************************************************************
	//  private Methods
	//******************************************************************************
	private async getPermissions() {
		this.canCreate = await this.userPermissionService.canCreateProjects();
	}

	private async CheckChartsAccess(): Promise<boolean> {
		return (new ProjectsAuthorizer()).isFeatureAuthorized(this.httpService, ProjectFeatures.Charts);
	}
}
