<div class="content">
    <div class="name"><span>{{currentDeveloper? currentDeveloper.name : '-' }}</span></div>
    <div title="Edit Developer" class="icon material-icons-outlined cursor edit-icon" (click)="showModal()">edit</div>
</div>

<div *ngIf="searchMode" class="search-modal">
    <div class="locked"></div>
    <div class="window">
        <div class="header">
            <div class="header-text">Find Developer</div>
            <div title="Close" class="icon material-icons-outlined cursor close" style="float: right;" (click)="close();">close</div>
        </div>
        <div class="content">
            <div *ngIf="!addDeveloperMode" class="search-header">
                <div class="search-prompt">
                    <sdk-textbox [hint]="'Type part of developer name then pause or click the search icon'" [value]="searchText" (changeCallBackEvent)="setSearchText($event)"></sdk-textbox>
                </div>
                <div title="Search" 
                     class="icon material-icons-outlined cursor search-button" 
                     [ngClass]="{disabled: !searchText || searchText === ''}"
                     (click)="findDevelopers()">search</div>
            </div>
            <div *ngIf="isLoading" class="selection">
                <sdk-loading [isLoading]="isLoading"></sdk-loading>
            </div>
            <div *ngIf="!isLoading" class="selection">

                <div *ngIf="!addDeveloperMode" class="selection-header">{{(searchResults && searchResults.length > 0)? searchResults.length + ' items found' : (searchInitiated? 'No Results found' : '')}}</div>
                <div *ngIf="addDeveloperMode" class="add-header">New Developer</div>
                <div *ngIf="searchInitiated && !addDeveloperMode" class="add-developer">
                    <span *ngIf="searchResults && searchResults.length > 0">
                        Select from the list (Recommended) or
                        <button class="button small" (click)="addDeveloperClick()"> + add new developer</button>
                    </span>
                    <span *ngIf="searchResults && searchResults.length <= 0">
                        <button class="button small" (click)="addDeveloperClick()"> + add new developer</button>
                    </span>
                </div>
                <div *ngIf="searchInitiated && addDeveloperMode" class="new-developer">
                    <div class="new-developer-name">
                        <sdk-textbox [hint]="'Type new developer name'" [value]="newDeveloperName" (changeCallBackEvent)="setNewDeveloperText($event)"></sdk-textbox>
                    </div>
                    <div class="icon material-icons-outlined cursor save-developer-icon"
                         [ngClass]="{disabled: !newDeveloperName || newDeveloperName.length === 0}"
                         [title]="'Save and Select'"
                         (click)="saveDeveloperClick()">done</div>
                    <div class="icon material-icons-outlined cursor cancel-add-icon"
                         [title]="'Cancel'"
                         (click)="cancelAddDeveloperClick()">clear</div>
                </div>
                <table *ngIf="!addDeveloperMode" class="selection-table">
                    <tbody>
                        <ng-container *ngFor="let developer of searchResults">
                            <tr class="table-row"
                                [ngClass]="{ selected: selectedDeveloper && selectedDeveloper.name === developer.name}"
                                (click)="setDeveloper(developer)">
                                <td class="table-data">
                                    <div class="item">
                                        <ng-container *ngFor="let source of developer.sources">
                                            <div [ngClass]="{ selected: selectedDeveloper && selectedDeveloper.name === developer.name}"
                                                 class="icon material-icons-outlined source-icon"
                                                 [title]="source">
                                                {{getSourceIcon(source)}}
                                            </div>
                                        </ng-container>
                                        <div class="developer"
                                             [ngClass]="{ selected: selectedDeveloper && selectedDeveloper.name === developer.name}"
                                             title="{{developer.name}}">
                                            {{developer.name}}
                                        </div>
                                        <div *ngIf="selectedDeveloper && selectedDeveloper.name === developer.name"
                                             class="icon material-icons-outlined cursor selected-icon">
                                            check_circle_outline
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="footer">
            <div class="actions">
                <button [disabled]="selectedDeveloper === undefined" (click)="select()">Select</button>
            </div>
        </div>
    </div>
</div>
