<div class="content">
    <div class="data">
        <ccms-map-viewer *ngIf="showMap" [location]="location" [dataSources]="selectedDataSources" [place]="place">
        </ccms-map-viewer>
        <img *ngIf="!showMap" alt="No Location" src="assets/images/project.png" width="100%" />

    </div>
    <div class="info">
        <table class="table-x">
            <caption style="display: none;">Location</caption>
            <tbody>
                <tr>
                    <th scope="col">Project ID:</th>
                    <td [title]="project.id || '-'">{{ project.id || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Status:</th>
                    <td>{{ project.status || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Registry:</th>
                    <td><a [href]="project.registryUrl" target="_blank">{{ project.registry }}</a></td>
                </tr>
                <tr>
                    <th scope="col">Country:</th>
                    <td>{{ project.country || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Region:</th>
                    <td>{{ project.region || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Site:</th>
                    <td>{{ project.siteLocation || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">State:</th>
                    <td>{{ project.state || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Coordinates:</th>
                    <td>{{ getCoordinates() || '-' }}</td>
                </tr>
            </tbody>
        </table>
        <div class="header" *ngIf="showAlerts">
            Alerts
        </div>
        <div class="grid" *ngIf="showAlerts">
                <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial" [data]="alerts" [columns]="columns"
                    [isLoading]="isLoading" [datasets]="datasets" [showOptions]="false"
                    [uniqueIdentifier]="'metaregistry-detail-location-alerts'" [name]="activeView"
                    (loadDataEvent)="loadData($event)"></sdk-datagrid>
        </div>
        <div class="map-files" *ngIf="canEditMaps && shapefiles && shapefiles.length > 0">
            <div class="header">
                Maps
                <div *ngIf="isLoading" class="loading-container">
                    <span class="loading-text">Loading maps...</span>
                    <mat-progress-spinner class="loading-indicator" mode="indeterminate"
                        diameter="20"></mat-progress-spinner>
                </div>
            </div>
            <ul class="map-file-list" *ngIf="hasShapefiles">
                <li *ngFor="let shapefile of shapefiles; let i = index" class="map-file-item">
                    <input type="radio" [value]="shapefile" [(ngModel)]="selectedShapefile"
                        name="shapefileSelection" [checked]="shapefile.isCurated" (click)="radioClicked(shapefile)"
                        class="radio-btn">
                    <span class="icon material-icons-outlined view-toggle-btn" (click)="toggleShapefile(i)">
                        {{ isMapFileVisible(i) ? 'visibility_on' : 'visibility_off' }}
                    </span>
                    <a *ngIf="shapefile.registryLink" href="{{ shapefile.registryLink }}"
                        title="{{ shapefile.registryLink }}" class="map-file-info" target="_blank"
                        rel="noopener noreferrer">
                        {{ shapefile.name }} ({{ shapefile.container }})
                    </a>
                    <span *ngIf="!shapefile.registryLink" class="map-file-info">
                        {{ shapefile.name }} ({{ shapefile.container }})
                    </span>
                    <span (click)="downloadShapefile(i)" class="icon material-icons-outlined download-file-btn">
                        download
                    </span>
                </li>
            </ul>
            <div *ngIf="!isLoading" class="map-buttons">
                <ccms-icon-button
                    [disabled]="(selectedShapefile && selectedShapefile.isCurated) || !selectedShapefile"
                    class="small" (click)="promoteMap()">Promote Map</ccms-icon-button>
                <ccms-icon-button
                    [disabled]="!selectedShapefile || !(selectedShapefile && selectedShapefile.isCurated)"
                    class="small" (click)="demoteMap()">Demote map</ccms-icon-button>
            </div>
            <ng-container *ngIf="alertMessage">
                <ccms-alert [type]="alertType" [message]="alertMessage" class="alert-container"></ccms-alert>
            </ng-container>
        </div>
    </div>
</div>