import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { Subject, Subscription } from 'rxjs';

import { Clone } from '~/classes/shared/clone';

import { ProjectService } from '~/services/shared/projects/project.service';
import { SustainabilityService } from '~/services/shared/sustainability.service';
import { ProjectConstants } from '~/models/shared/cvxproject';

@Component({
    selector: 'project-detail-info',
    templateUrl: './project-detail-info.component.html',
    styleUrls: ['./project-detail-info.component.scss']
})

export class ProjectDetailInfoComponent implements OnDestroy {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = false;
    public projectEdit: any;
    public projectStatusList: any = [];
    public projectStatusCategoryList: string[] = [];
    public projectDNPStatusList: any = [];
    public sustainabilityList: any = [];
    public showStatusExt: boolean = false;
    public showDNPStatusExt: boolean = false;
    public projectStatusHistory: any = [];
    public maxDescriptionLength = ProjectConstants.MaxProjectDescriptionLength;

    public faHistory = faHistory;

    private subscriptions: Subscription[] = [];

    constructor(
        private projectsService: ProjectService,
        private sustainabilityService: SustainabilityService,
    ) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public ngOnInit() {
        this.sortSustainabilityList();
        this.loadProjectStatusHistory();
    }

    public async ngOnChanges() {
        if (this.saveData) {
            this.saveDataEvent.emit(false);
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = Clone.deepCopy(this.project);
            this._projectStatusSubject.next(this.project.status);
            await this.loadProjectStatusList();
            await this.buildSustainabilityList();
        }

        await this.setDNPReason(this.project);
        this.setDNPOther(this.project);
        this.loadProjectStatusHistory();

        this.isLoading = false;
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;

        this.changeEvent.emit(this.projectEdit);
    }

    public setDescription(event: any) {
        this.projectEdit.description = event.target.value;

        this.changeEvent.emit(this.projectEdit);
    }

    private _projectStatusSubject = new Subject<any>();
    public ProjectStatus$ = this._projectStatusSubject.asObservable();

    public async setStatus(event: any) {
        let value = {};
        if (event.target.value) {
            value = JSON.parse(event.target.value);
        }

        console.log("Status changed...", value);
        this.projectEdit.status = value;
        await this.setDNPReason(this.projectEdit);
        this._projectStatusSubject.next(value);
        this.changeEvent.emit(this.projectEdit);
    }

    public setDNPStatus(event: any) {
        if (event.target.value === "") {
            this.projectEdit.didNotPursueReason = {};
        } else {
            this.projectEdit.didNotPursueReason = JSON.parse(event.target.value);
        }

        this.setDNPOther(this.projectEdit);

        this.changeEvent.emit(this.projectEdit);
    }

    public setNotes(field: any, event: any) {
        this.projectEdit[field] = event.target.value;

        this.changeEvent.emit(this.projectEdit);
    }

    public getGoal(goal: any) {
        return this.projectEdit.sustainableDevelopmentGoals.find((g: any) => g.id && g.id === goal.id);
    }

    public setGoal(event: any, goal: any) {
        let ndx = this.sustainabilityList.findIndex((g: any) => g.id === goal.id);

        if (ndx > -1) {
            this.sustainabilityList[ndx].selected = event.target.checked;
        }

        this.projectEdit.sustainableDevelopmentGoals = this.sustainabilityList.map((g: any) => ({ id: g.id, selected: g.selected }));

        this.changeEvent.emit(this.projectEdit);
    }

    public getProjectStatusHistoryTooltip(): string | null {
        if (this.projectStatusHistory) {
            return this.projectStatusHistory.map(history => `${history.previousStatus} => ${history.currentStatus} - ${new Date(history.timestamp).toLocaleDateString()} - ${history.userName.split("[")[0]}`).join("\r\n")
        }

        return null;
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private async loadProjectStatusList(): Promise<void> {
        this.projectStatusList = [];

        try {
            await this.projectsService.getStatuses().then((data: any) => {
                this.projectStatusList = data;
                this.projectStatusCategoryList = this.projectsService.extractUniqueStatusCategories(this.projectStatusList);
            });
        } catch (error: any) {
            this.projectStatusList = [];
            this.projectStatusCategoryList = [];
        }
    }

    private async loadDNPStatusList(): Promise<void> {
        this.projectDNPStatusList = [];

        try {
            await this.projectsService.getDNPStatuses().then((data: any) => {
                this.projectDNPStatusList = data;
            });
        } catch (error: any) {
            this.projectDNPStatusList = [];
        }
    }

    private async setDNPReason(obj: any) {
        if (obj.status?.name === "Did Not Pursue") {
            await this.loadDNPStatusList();
            this.showStatusExt = true;
        } else {
            this.showStatusExt = false;
            this.showDNPStatusExt = false;

            obj.didNotPursueReason = null;
            obj.didNotPursueReasonOther = null;
        }
    }

    private setDNPOther(obj: any) {
        if (obj.didNotPursueReason?.name === "Other") {
            this.showDNPStatusExt = true;
        } else {
            this.showDNPStatusExt = false;

            obj.didNotPursueReasonOther = null;
        }
    }

    private sortSustainabilityList() {
        if (this.project?.sustainableDevelopmentGoals) {
            this.project.sustainableDevelopmentGoals.sort((a: any, b: any) => (a.id < b.id ? -1 : 1));
        }
    }

    private async buildSustainabilityList() {
        this.sustainabilityList = [];

        try {
            await this.sustainabilityService.getTypes().then((data: any) => {
                this.sustainabilityList = data;

                this.sustainabilityList.forEach((goal: any) => {
                    goal.selected = this.getGoal(goal) ? true : false;
                })
            });
        } catch (error: any) {
            this.sustainabilityList = [];
        }
    }

    private loadProjectStatusHistory() {
        const subscription = this.projectsService
            .getProjectStatusHistory(this.project.id)
            .subscribe((data: any) => this.projectStatusHistory = data);

        this.subscriptions.push(subscription);
    }
}
