import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

import { AuthGuard } from '~/classes/core/auth/auth.guard';

import { AuthComponent } from '~/pages/auth/auth.component';
import { NotFoundComponent } from '~/pages/notfound/notfound.component';
import { HomeComponent } from '~/pages/home/home.component';
import { MetaRegistryComponent } from '~/pages/metaregistry/metaregistry.component';
import { MetaRegistryDetailComponent } from '~/pages/metaregistry/metaregistry-detail/metaregistry-detail.component';
import { MetaRegistryRetirementsComponent } from '~/pages/metaregistry/retirements/retirements.component';
import { MarketsComponent } from '~/pages/markets/markets.component';
import { InventoryComponent } from '~/pages/inventory/inventory.component';
import { ProjectsComponent } from '~/pages/projects/projects.component';
import { ProjectDetailComponent } from '~/pages/projects/project-detail/project-detail.component';
import { PolicyTrackerComponent } from '~/pages/policy-tracker/policy-tracker.component';

import { ErrorComponent } from '~/components/core/error/error.component';
import { AccessComponent } from '~/components/core/access/access.component';
import { PolicyTrackerDetailPage } from '~/pages/policy-tracker/detail/policy-tracker-detail.page';
import { PolicyTrackerCountryDetailPage } from '~/pages/policy-tracker/country-detail/policy-tracker-country-detail.page';
import { SearchResultsPage } from '~/pages/search/search-results.page';
import { AdminComponent } from '~/pages/admin/admin.component';
import { DeveloperScreeningComponent } from '~/pages/developers-screening/developer-screening.component';
import { DeveloperDetailComponent } from '~/pages/developers-screening/developer-detail/developer-detail.component';
import { ReportsComponent } from '~/pages/powerbi/reports.component';
import { ProjectsAuthorizer } from '~/models/shared/projects/projects-authorizer';
import { ProjectsAuthGuard } from '~/models/shared/projects/projects-auth-guard';

export const ROUTE_ID_PROJECT_DETAIL = "project-detail";
export const ROUTE_ID_METAREGISTRY_DETAIL = "metaregistry-detail";
export const ROUTE_ID_INVENTORY = "inventory";
export const ROUTE_ID_DEVELOPER_DETAIL = "developer-detail";

export const routes: any[] = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'notfound',
        component: NotFoundComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        title: 'Overview',
        icon: 'home',
        isNav: true,
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'metaregistry',
        title: 'Meta Registry',
        icon: 'public',
        isNav: true,
        component: MetaRegistryComponent,
        canActivate: [AuthGuard],
        id: 1
    },
    {
        path: 'developers-screening',
        title: 'Developer Screening',
        icon: 'groups_2',
        isNav: true,
        component: DeveloperScreeningComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        title: 'Reports',
        isNav: true,
        roles: ["CarbonCredits.Admin", "CarbonCredits.Management", "CarbonCredits-ProjectReaders"],
        icon: 'analytics',
        canActivate: [AuthGuard],
        component: ReportsComponent
    },
    {
        data: {id: ROUTE_ID_METAREGISTRY_DETAIL},
        path: 'metaregistry/detail',
        component: MetaRegistryDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        data: { id: ROUTE_ID_DEVELOPER_DETAIL },
        path: 'developers-screening/detail',
        component: DeveloperDetailComponent,
        canActivate: [AuthGuard],
        title: 'Developers',
        icon: 'content_paste_go'
    },
    {
        path: 'metaregistry/retirements',
        title: 'Credit Retirements',
        icon: 'content_paste_go',
        isSubNav: true,
        parentId: 1,
        order: 0,
        component: MetaRegistryRetirementsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'markets',
        title: 'Carbon Markets',
        icon: 'show_chart',
        isNav: true,
        component: MarketsComponent,
        canActivate: [AuthGuard]
    },
    {
        data: {id: ROUTE_ID_INVENTORY},
        path: 'inventory',
        title: 'Inventory',
        icon: 'pallet',
        roles: ["CarbonCredits.Admin", "CarbonCredits.Inventory.ABU", "CarbonCredits.Inventory.S&T"],
        isNav: true,
        component: InventoryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'projects',
        title: 'Offset Projects',
        icon: 'forest',
        roles: ["CarbonCredits.Admin", "CarbonCredits.Management", "CarbonCredits-ProjectReaders"],
        isNav: true,
        component: ProjectsComponent,
        canActivate: [ProjectsAuthGuard],
        navAuthorizer: new ProjectsAuthorizer()
        
    },
    {
        data: {id: ROUTE_ID_PROJECT_DETAIL},
        path: 'projects/detail',
        roles: ["CarbonCredits.Admin", "CarbonCredits.Management", "CarbonCredits-ProjectReaders"],
        component: ProjectDetailComponent,
        canActivate: [ProjectsAuthGuard]
    },
    {
        path: 'policy-tracker',
        title: 'Policy Tracker',
        icon: 'policy',
        isNav: true,
        component: PolicyTrackerComponent,
        canActivate: [AuthGuard],
    },
    {
      path: 'policy-tracker/:marketName',
      title: 'Policy Tracker Market Detail',
      icon: 'policy',
      isNav: false,
      component: PolicyTrackerDetailPage,
      canActivate: [AuthGuard],
    },
    {
      path: 'policy-tracker/country/:alpha3Code',
      title: 'Policy Tracker Country Detail',
      icon: 'policy',
      isNav: false,
      component: PolicyTrackerCountryDetailPage,
      canActivate: [AuthGuard],
    },
    {
      path: 'search',
      title: 'Search',
      icon: 'search',
      isNav: false,
      component: SearchResultsPage,
      canActivate: [AuthGuard],
    },
    {
      path: 'admin',
      title: 'Admin',
      icon: 'admin_panel_settings',
      isNav: true,
      roles: ["CarbonCredits.Admin"],
      component: AdminComponent,
      canActivate: [AuthGuard]
    },
   
    {
        path: 'access',
        component: AccessComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: '**',
        redirectTo: 'notfound',
        pathMatch: 'full'
    }
   
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRouting { }
